import { useState } from "react";
import "./style.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";

const Header = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMenu = () => setClick(false);

  return (
    <header className="header">
      <nav className="navbar">
        <Link
          to="intro"
          spy={true}
          smooth={true}
          offset={-90}
          duration={700}
          onClick={closeMenu}
          className="logo"
        >
          B
        </Link>
        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes style={{ color: "#ffffff" }} />
          ) : (
            <FaBars style={{ color: "#ffffff" }} />
          )}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              activeClass="scrollActive"
              to="intro"
              spy={true}
              smooth={true}
              offset={-90}
              duration={700}
              onClick={closeMenu}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              activeClass="scrollActive"
              to="about"
              spy={true}
              smooth={true}
              offset={-90}
              duration={700}
              onClick={closeMenu}
            >
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link
              activeClass="scrollActive"
              to="resume"
              spy={true}
              smooth={true}
              offset={-90}
              duration={700}
              onClick={closeMenu}
            >
              Resume
            </Link>
          </li>
          <li className="nav-item">
            <Link
              activeClass="scrollActive"
              to="portfolio"
              spy={true}
              smooth={true}
              offset={-90}
              duration={700}
              onClick={closeMenu}
            >
              Portfolio
            </Link>
          </li>
          <li className="nav-item">
            <Link
              activeClass="scrollActive"
              to="certificates"
              spy={true}
              smooth={true}
              offset={-90}
              duration={700}
              onClick={closeMenu}
            >
              Certificates
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
