import "./style.css";
import { IoMdBusiness } from "react-icons/io";
import { GiGraduateCap } from "react-icons/gi";

const Resume = () => {
  return (
    <section id="resume" className="grey-section">
      <div className="row section-intro">
        <div className="col-twelve">
          <h5>Resume</h5>
          <h1>More of my credentials.</h1>
        </div>
      </div>

      <div className="row resume-timeline">
        <div className="col-twelve resume-header">
          <h2>Work Experience</h2>
        </div>

        <div className="col-twelve">
          <div className="timeline-wrap">
            <div className="timeline-block">
              <div className="timeline-ico">
                <IoMdBusiness />
              </div>

              <div className="timeline-header">
                <h3>Frontend Developer</h3>
                <p>08/2022 - Present</p>
              </div>

              <div className="timeline-content">
                <h4>De Marke Labs</h4>
                <ul>
                  <li>
                    Developed a web app with Next.js and Tailwind CSS for
                    language learning via video chat and messaging. Created user
                    authentication functionalities. Integrated real-time
                    communication with WebRTC. Utilized Redux Toolkit for state
                    management. Enhanced an admin control panel with Bootstrap
                    and React.js.
                  </li>
                  <li>
                    Integrated an AI-powered system into an Angular project for
                    streamlined job application review. This system analyzes CVs
                    using AI to provide suitability scores, sorting them
                    accordingly. Additionally, various UI enhancements have been
                    conducted within the project.
                  </li>
                  <li>
                    A mobile application for the renowned Turkish brand Kale
                    Group's website, Usta Kulübü, was built from scratch using
                    Ionic. Figma designs were applied to the project, and login
                    and register systems were integrated. All other
                    functionalities present on the website were also transformed
                    into functional mobile features.
                  </li>
                  <li>
                    Implemented new features and enhancements to an existing
                    React Native emergency call app for the elderly population,
                    including a simple button press to initiate calls, location
                    tracking, and sound detection for added safety.
                  </li>
                  <li>
                    Involved in Agile process, 2 week Sprints and daily Scrums
                    to discuss the development of the application.
                  </li>
                </ul>
                <p></p>
              </div>
            </div>

            <div className="timeline-block">
              <div className="timeline-ico">
                <IoMdBusiness />
              </div>

              <div className="timeline-header">
                <h3>React Developer Intern</h3>
                <p>10/2021 - 12/2021</p>
              </div>

              <div className="timeline-content">
                <h4>Makas</h4>
                <ul>
                  <li>
                    Created stable and maintainable codebases using React.
                  </li>
                  <li>Adopted a mobile-first approach to existing websites.</li>
                </ul>
              </div>
            </div>

            <div className="timeline-block">
              <div className="timeline-ico">
                <IoMdBusiness />
              </div>

              <div className="timeline-header">
                <h3>Frontend Developer Intern</h3>
                <p>08/2021 - 11/2021</p>
              </div>

              <div className="timeline-content">
                <h4>Janus</h4>
                <ul>
                  <li>
                    Developed interactive UI's that surpassed client objectives
                    and improved user experience.
                  </li>
                  <li>
                    Created modular, responsive templates using modern CSS
                    techniques and Javascript libraries.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row resume-timeline">
        <div className="col-twelve resume-header">
          <h2>Education</h2>
        </div>

        <div className="col-twelve">
          <div className="timeline-wrap">
            <div className="timeline-block">
              <div className="timeline-ico">
                <GiGraduateCap />
              </div>

              <div className="timeline-header">
                <h3>Bachelor's Degree</h3>
                <p>2017 - 2022 | Izmir</p>
              </div>

              <div className="timeline-content">
                <h4>Dokuz Eylul University - Management Information Systems</h4>
                <p>GPA : 3.07 / 4.00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
