import { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/Header/header";
import Intro from "./components/Intro/intro";
import About from "./components/About/about";
import Resume from "./components/Resume/resume";
import Portfolio from "./components/Portfolio/portfolio";
import Footer from "./components/Footer/footer";
import GridLoader from "react-spinners/GridLoader";
import Certificates from "./components/Certificates/certificates";

function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <GridLoader
            color={"#743cc3"}
            loading={loading}
            size={30}
            aria-label="Loading Spinner"
          />
        </div>
      ) : (
        <>
          <Header />
          
          <Intro />

          <About />

          <Resume />

          <Portfolio />

          <Certificates />

          <Footer isScrolled={isScrolled} />
        </>
      )}
    </>
  );
}

export default App;
