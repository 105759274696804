import "./style.css";
import { AiOutlineArrowUp } from "react-icons/ai";
import { Link } from "react-scroll";

const Footer = ({ isScrolled }) => {
  return (
    <footer>
      <div className="row">
        <div className="col-twelve tab-full">
          <div className="copyright">
            <span>© Copyright {new Date().getFullYear()}</span>
            <span>
              Design by <span className="berkan">Berkan Ankal</span>
            </span>
          </div>
        </div>

        {isScrolled && (
          <div id="go-top">
            <Link
              className="smoothscroll"
              to="intro"
              spy={true}
              smooth={true}
              offset={-90}
              duration={700}
            >
              <AiOutlineArrowUp size={25} />
            </Link>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
