import "./style.css";

const Certificates = () => {
  return (
    <section id="certificates">
      <div className="row section-intro">
        <div className="col-twelve">
          <h5>Certificates</h5>
          <h1>Check Out My Certificates.</h1>
        </div>
      </div>

      <div className="row">
        <div className="projects">
          <div className="card">
            <a
              href="https://verified.sertifier.com/en/verify/98318067446406"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="card-img"
                src={require("../../assets/images/certificates/alotech.png")}
                alt="project"
              />
              <div className="card-content">
                <h3 className="card-title">AloTech FullStack Bootcamp</h3>
                <p className="card-text">
                  3 months React, Node.js, Django camp.
                </p>
              </div>
            </a>
          </div>
          <div className="card">
            <a
              href="https://verified.sertifier.com/en/verify/11715798439220"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="card-img"
                src={require("../../assets/images/certificates/akbank.png")}
                alt="project"
              />

              <div className="card-content">
                <h3 className="card-title">Akbank React Native Bootcamp</h3>
                <p className="card-text">3 months React Native camp.</p>
              </div>
            </a>
          </div>
          <div className="card">
            <a
              href="https://certificates.techcareer.net/tr/verify/24840481487312?ref"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="card-img"
                src={require("../../assets/images/certificates/techcareer.png")}
                alt="project"
              />
              <div className="card-content">
                <h3 className="card-title">Angular Bootcamp</h3>
                <p className="card-text">1 month Angular camp.</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certificates;
