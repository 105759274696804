import "./style.css";
import HTML from "../../assets/icons/html.svg";
import CSS from "../../assets/icons/css.svg";
import JS from "../../assets/icons/javascript.svg";
import Bootstrap from "../../assets/icons/bootstrap.svg";
import MUI from "../../assets/icons/mui.svg";
import Tailwind from "../../assets/icons/tailwind.svg";
import ReactIcon from "../../assets/icons/react.svg";
import Angular from "../../assets/icons/angular.svg";
import Typescript from "../../assets/icons/typescript.svg";
import ReactNative from "../../assets/icons/react-native.svg";
import Firebase from "../../assets/icons/firebase.svg";
import NodeJS from "../../assets/icons/node-js.svg";
import Mongo from "../../assets/icons/mongo.svg";
import SQL from "../../assets/icons/database.svg";
import Ionic from "../../assets/icons/ionic.svg";
import Parse from "../../assets/icons/parse.svg";
import Artificial from "../../assets/icons/artificial.svg";
import Rocket from "../../assets/icons/rocket.svg";
import Camping from "../../assets/icons/camping.svg";
import Climbing from "../../assets/icons/climbing.svg";
import Reading from "../../assets/icons/reading.svg";
import Chess from "../../assets/icons/chess.svg";

const About = () => {
  return (
    <section id="about">
      <div className="row section-intro">
        <div className="col-twelve">
          <h5>About</h5>
          <h1>Let me introduce myself.</h1>

          <div className="intro-info">
            <p className="lead">
              A highly motivated developer with a passion for innovation and
              problem-solving. I have a strong commitment to delivering
              user-friendly solutions and I am continuously striving to learn
              and improve my skills to reach my goals. I enjoy researching and
              reading to stay up to date with new technologies and trends to
              ensure my solutions are up-to-date. With my proactive attitude,
              attention to detail and problem-solving skills, I am confident
              that I can be a valuable asset to any organisation.
            </p>
          </div>
        </div>
      </div>

      <div className="row about-content">
        <div className="col-twelve">
          <h3>Interests</h3>

          <div className="skills">
            <div className="skill">
              <img src={Artificial} alt="Artificial Intelligence" />
              <span>Artificial Intelligence</span>
            </div>
            <div className="skill">
              <img src={Chess} alt="Chess" />
              <span>Chess</span>
            </div>
            <div className="skill">
              <img src={Rocket} alt="Astronomy" />
              <span>Astronomy</span>
            </div>
            <div className="skill">
              <img src={Camping} alt="Camping" />
              <span>Camping</span>
            </div>
            <div className="skill">
              <img src={Climbing} alt="Climbing" />
              <span>Climbing</span>
            </div>
            <div className="skill">
              <img src={Reading} alt="Reading Book" />
              <span>Reading Book</span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row about-content"
        style={{
          marginTop: "50px",
        }}
      >
        <div className="col-twelve">
          <h3>Skills</h3>

          <div className="skills">
            <div className="skill">
              <img src={HTML} alt="HTML" />
              <span>HTML</span>
            </div>
            <div className="skill">
              <img src={CSS} alt="CSS" />
              <span>CSS</span>
            </div>
            <div className="skill">
              <img src={JS} alt="Javascript" />
              <span>Javascript</span>
            </div>
            <div className="skill">
              <img src={Bootstrap} alt="Bootstrap" />
              <span>Bootstrap</span>
            </div>
            <div className="skill">
              <img src={MUI} alt="Material UI" />
              <span>Material UI</span>
            </div>
            <div className="skill">
              <img src={Tailwind} alt="Tailwind CSS" />
              <span>Tailwind CSS</span>
            </div>
            <div className="skill">
              <img src={ReactIcon} alt="React" />
              <span>React</span>
            </div>
            <div className="skill">
              <img
                src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original-wordmark.svg"
                alt="Next.js"
              />
              <span>Next.js</span>
            </div>
            <div className="skill">
              <img src={Angular} alt="Angular" />
              <span>Angular</span>
            </div>
            <div className="skill">
              <img src={Typescript} alt="Typescript" />
              <span>Typescript</span>
            </div>
            <div className="skill">
              <img src={ReactNative} alt="React Native" />
              <span>React Native</span>
            </div>
            <div className="skill">
              <img src={Ionic} alt="React Native" />
              <span>Ionic</span>
            </div>
            <div className="skill">
              <img src={Firebase} alt="Firebase" />
              <span>Firebase</span>
            </div>
            <div className="skill">
              <img src={Parse} alt="Parse" />
              <span>Parse</span>
            </div>
            <div className="skill">
              <img src={NodeJS} alt="Node.js" />
              <span>Node.js</span>
            </div>
            <div className="skill">
              <img
                src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original-wordmark.svg"
                alt="Express.js"
              />

              <span>Express.js</span>
            </div>
            <div className="skill">
              <img src={Mongo} alt="MongoDB" />
              <span>MongoDB</span>
            </div>
            <div className="skill">
              <img src={SQL} alt="SQL" />
              <span>SQL</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
