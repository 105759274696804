import "./style.css";
import { AiOutlineTwitter, AiFillGithub, AiOutlineMail } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { SiVsco } from "react-icons/si";
import { FaMedium } from "react-icons/fa";
import { SlArrowDown } from "react-icons/sl";
import { Link } from "react-scroll";

const Intro = () => {
  return (
    <section id="intro">
      <div className="intro-overlay"></div>

      <div className="intro-content">
        <div className="row">
          <div className="col-twelve">
            <h5>Hello, World.</h5>
            <h1>I'm Berkan Ankal</h1>

            <p className="intro-position">
              <span>Front-end Developer</span>
            </p>

            <Link
              className="button stroke"
              to="about"
              spy={true}
              smooth={true}
              duration={700}
              offset={-90}
            >
              More About Me{" "}
              <SlArrowDown
                style={{
                  paddingTop: 1,
                  marginLeft: 5,
                }}
              />
            </Link>
          </div>
        </div>
      </div>

      <ul className="intro-social">
        <li>
          <a
            href="https://www.linkedin.com/in/berkanankal/"
            target="_blank"
            rel="noreferrer"
          >
            <BsLinkedin />
          </a>
        </li>
        <li>
          <a
            href="https://github.com/berkanankal"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillGithub />
          </a>
        </li>

        <li>
          <a
            href="https://medium.com/@berkan.ankal"
            target="_blank"
            rel="noreferrer"
          >
            <FaMedium />
          </a>
        </li>
        <li>
          <a href="mailto:berkan.ankal@outlook.com">
            <AiOutlineMail />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/berkanankal"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineTwitter />
          </a>
        </li>
        <li>
          <a
            href="https://vsco.co/berkanankal"
            target="_blank"
            rel="noreferrer"
          >
            <SiVsco />
          </a>
        </li>
      </ul>
    </section>
  );
};

export default Intro;
