import "./style.css";
import { AiFillGithub } from "react-icons/ai";

const Portfolio = () => {
  return (
    <section id="portfolio">
      <div className="row section-intro">
        <div className="col-twelve">
          <h5>Portfolio</h5>
          <h1>Check Out Some of My Works.</h1>
        </div>
      </div>

      <div className="row">
        <div className="projects">
          <div className="card">
            <img
              className="card-img"
              src={require("../../assets/images/portfolio/memories-app.png")}
              alt="project"
            />
            <div className="card-content">
              <h3 className="card-title">Memories App</h3>
              <p className="card-text">
                A mern stack project where you can share your memories, interact
                and socialize with the memories of others.
                <br />
                <br />
              </p>
              <div className="card-links">
                <a
                  href="https://mern-app-pxi3.onrender.com"
                  target="_blank"
                  rel="noreferrer"
                  className="demo-btn"
                >
                  Live Demo
                </a>
                <a
                  href="https://github.com/berkanankal/memories-app"
                  target="_blank"
                  rel="noreferrer"
                  className="github-btn"
                >
                  Github
                </a>
              </div>
            </div>
          </div>
          <div className="card">
            <img
              className="card-img"
              src={require("../../assets/images/portfolio/netflix-clone.png")}
              alt="project"
            />
            <div className="card-content">
              <h3 className="card-title">Netflix Clone</h3>
              <p className="card-text">
                A Netflix Clone project that I developed mainly to improve
                myself on Tailwind, Typescript and Next.js.
                <br />
                <br />
              </p>
              <div className="card-links">
                <a
                  href="https://netflix-clone-berkanankal.vercel.app"
                  target="_blank"
                  rel="noreferrer"
                  className="demo-btn"
                >
                  Live Demo
                </a>
                <a
                  href="https://github.com/berkanankal/netflix-clone"
                  target="_blank"
                  rel="noreferrer"
                  className="github-btn"
                >
                  Github
                </a>
              </div>
            </div>
          </div>
          <div className="card">
            <img
              className="card-img"
              src={require("../../assets/images/portfolio/uni-x.png")}
              alt="project"
            />
            <div className="card-content">
              <h3 className="card-title">uni-X</h3>
              <p className="card-text">
                This is my graduation project. Thanks to this application,
                students can buy and sell second-hand items, notes, books, and
                find a roommate or a place to stay.
              </p>
              <div className="card-links">
                <a
                  href="https://uni-x.onrender.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="demo-btn"
                >
                  Live Demo
                </a>
                <a
                  href="https://github.com/berkanankal/uni-X"
                  target="_blank"
                  rel="noreferrer"
                  className="github-btn"
                >
                  Github
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row all-projects-btn">
        <a
          href="https://github.com/berkanankal"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillGithub size={30} />
          See All Projects
        </a>
      </div>
    </section>
  );
};

export default Portfolio;
